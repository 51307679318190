// Chakra imports
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Alert,
  Textarea
} from "@chakra-ui/react";
import { SignageClient } from "components/ksin/ApiClient/SignageClient";
import PixelStreamer from "components/ksin/Streamer/PixelStreamer";
import VideoCaller from "components/ksin/Streamer/VideoCaller";
import { AppContext } from "layouts/AppCommons";
import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { useKey } from "react-use";
import { SignageLayoutList, SignageStateList } from "./SignageTemplateUpdate";
import { UserClient } from "components/ksin/ApiClient/UserClient";
import moment from "moment";
import { CharacterClient } from "components/ksin/ApiClient/CharacterClient";



const signageClient = new SignageClient();
const userClient = new UserClient();

const AlertConfirmLogout = ({ isOpen, onHide }) => {
  const initialRef = React.useRef(null)
  const finalRef = React.useRef();
  const handleLogout = () => {
    onHide();
    userClient.logout().then(() => {
      window.location.href = "/login";
    });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onHide}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>ログアウト</ModalHeader>
        <ModalBody pb={2}>
          <p>ログアウトしますか？</p>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onHide}>
            キャンセル
          </Button>
          <Button
            colorScheme="red"
            onClick={handleLogout}
            ml={3}
            ref={initialRef}
            autoFocus
            _focus={{ boxShadow: "0 0 0 0.15rem rgb(225 83 97 / 50%)" }}
          >
            はい
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

function SignageView(props: any) {
  const { appContext, setAppContext } = useContext(AppContext);
  const wsc = appContext.wsc;

  // 対象サイネージID("SELF""で自分のユーザーデータを取得する)
  const targetId: string = "SELF";
  const state = useLocation();
  // 画面内で更新されるデータ
  const [signageInfo, setSignageInfo] = useState(null);
  const [signageId, setSignageId] = useState("");
  const [signageState, setSignageState] = React.useState(
    appContext.signageState ? appContext.signageState : SignageStateList.Standby
  );
  const [templateTable, setTemplateTable] = React.useState(null);
  const [isViewConfigurator, setIsViewConfigurator] = React.useState(false);
  const [isOverlayStreamer, setIsOverlayStreamer] = React.useState(false);
  const [callOfferTime, setIsCallOfferTime] = React.useState(0);
  const [callRequestTime, setCallRequestTime] = React.useState(0);
  const [isShareScreen, setIsShareScreen] = React.useState(false);
  const [isMonitoring, setIsMonitoring] = React.useState(false);
  const [selectCharacter, setSelectCharacter] = React.useState(null);
  const { isOpen, onOpen, onClose: onHide } = useDisclosure();
  const [settingButton, setSettingButton] = useState({
    data: [],
    direction: 'vertical'
  });
  const [button, setButton] = useState('');
  const [isAlert, setAlert] = useState(false);

  const [mainTelop, setMainTelop] = React.useState(null);

  // AI会話モード時の会話内容保持用
  const [systemTalkLatest, setSystemTalkLatest] = React.useState(null);
  const [userTalkLatest, setUserTalkLatest] = React.useState(null);

  // DBからテンプレデータを取得する関数
  const getTemplateData = () => {
    signageClient.get(targetId).then((data: any) => {
      setSignageInfo(data);
      setSignageId(data.id);
      // サイネージ毎のキャラクター設定機能
      setSelectCharacter(data.default_character.name);

      const signageStandBy = data.templates.find((item) => item.signage_state === 'Standby');

      setSettingButton({
        data: signageStandBy.touch_buttons.map((item) => ({
          id: item.id,
          textBtn: item.text,
          color: item.bg_color,
          fontSize: item.font_size,
          visible: item.is_view
        })),
        direction: signageStandBy.row
      });

    })
      .catch((error) => {
        console.log(error);
      })
  }

  // マウント後の単発処理
  useEffect(() => {
    // DBからテンプレデータの取得
    getTemplateData();
  }, []);

  useEffect(() => {
    if (appContext.requestLogout) {
      signageClient.logout().then(() => {
        window.location.href = '/login';
        setAppContext((prev) => {
          prev.requestLogout = false;
          return {
            ...prev
          }
        })
      });
    }
  }, [appContext.requestLogout])

  useEffect(() => {
    if (appContext.closeSignageWebReservationAvatar) {
      setAppContext((prev) => {
        prev.closeSignageWebReservationAvatar = false;
        return {
          ...prev
        }
      });
      logout()
    }
  }, [appContext.closeSignageWebReservationAvatar])


  const toggleViewConfigurator = useCallback(() => {
    console.log(isViewConfigurator);
    setIsViewConfigurator((prevState) => !prevState);
  }, []);

  // DBからテンプレート情報を取得したとき
  useEffect(() => {
    if (signageInfo) {
      const receiveTemplates = new Map();

      const uploadImageTableBase: any = {};
      const uploadImageInfo = {
        id: "",
        logo: null,
        media: null,
      };

      signageInfo.templates.forEach((data) => {
        type StateInfoType = {
          stateName: string;
          template: any;
        };
        const stateInfo: StateInfoType = {
          stateName: "",
          template: {},
        };

        try {
          stateInfo.stateName = data.signage_state;
          stateInfo.template = data;
          // メディアとロゴの画像パスを設定
          if (data.contents_media) {
            stateInfo.template.media_src = data.contents_media.image_url;
          } else {
            stateInfo.template.media_src = "/images/Common/ksin-logo.png";
          }

          if (data.logo_media) {
            stateInfo.template.logo_src = data.logo_media.image_url;
          } else {
            stateInfo.template.logo_src = "/images/Common/ksin-logo.png";
          }

          // レイアウトによりメディアの表示・非表示切り替え
          if (
            [SignageLayoutList.onlyAvatar].includes(stateInfo.template.layout)
          ) {
            stateInfo.template.is_view_media = false;
          } else {
            stateInfo.template.is_view_media = true;
          }

          receiveTemplates.set(stateInfo.stateName, stateInfo);

          // ロゴ画像とメディアファイル変更のための準備
          uploadImageInfo.id = data.id;
          uploadImageTableBase[stateInfo.stateName] = { ...uploadImageInfo };

          setTemplateTable(new Map(receiveTemplates));

        } catch (e) {
          console.log(`${new Date().toISOString()}[debug]signageView receiveTemplates Failed.`);
        }
      });
    }
  }, [signageInfo]);

  // キーボードイベント(リリース用)
  useKey("y", () => {
    console.log("callOffer by Pressed y.");
    setIsCallOfferTime(new Date().getTime());
  });

  useKey("c", () => {
    console.log("Pressed c.");
    toggleViewConfigurator();
  });

  const startSeconds = useRef(null);

  useKey("l", () => {
    let currTime = moment();

    if (!startSeconds.current) {
      startSeconds.current = currTime;
    }

    if (currTime.diff(startSeconds.current, 'seconds') === 3) {
      onOpen();
      startSeconds.current = null;
      return;
    }
  });

  // AIトークをリスタート
  const talkAIrestart = () => {
    signageClient.talkAIrestart()
      .then(json => {
        // resolve(json);
        console.log("talkAI restarted.")
        location.reload();
      })
      .catch((error) => {
        // reject(error);
      });
  }

  // 長押しver
  // useKey("a", () => {
  //   let currTime = moment();

  //   if (!startSeconds.current) {
  //     startSeconds.current = currTime;
  //   }

  //   if (currTime.diff(startSeconds.current, 'seconds') === 2) {
  //     talkAIrestart();
  //     startSeconds.current = null;
  //     return;
  //   }
  // });

  useKey("a", () => {
    console.log("Pressed a.");
    talkAIrestart();
  });

  useEffect(() => {
    if (!isOpen) {
      startSeconds.current = null;
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      e.preventDefault();
      startSeconds.current = null;
    });
    return window.removeEventListener('keyup', () => { });
  }, [])

  useEffect(() => {
    if (appContext.signageState) {
      console.log(
        `${new Date().toISOString()}[debug]signageView setSignageState: ${appContext.signageState
        }`
      );
      setSignageState(appContext.signageState);
    }
    setAppContext((pre) => {
      if (pre.signageState === SignageStateList.Standby) {
        console.log(`${new Date().toISOString()}[debug]SignageView call getTemplateData.`);
        getTemplateData();
      }
      return { ...pre };
    })
  }, [appContext.signageState]);

  useEffect(() => {
    if (appContext.isMonitoring) {
      setIsMonitoring(true);
    } else if (appContext.isMonitoring === false) {
      // } else {
      setIsMonitoring(false);
    }
  }, [appContext.isMonitoring]);

  useEffect(() => {
    if (appContext.receiveCallfromUser) {
      setCallRequestTime(new Date().getTime());
    }
  }, [appContext.receiveCallfromUser]);

  useEffect(() => {
    if (appContext.characterName) {
      setSelectCharacter(appContext.characterName);
    }
  }, [appContext.characterName]);

  useEffect(() => {
    console.log(
      `${new Date().toISOString()}[debug]signageView appContext.isShareScreen: ${appContext.isShareScreen
      }`
    );
    if (appContext.isShareScreen) {
      setIsShareScreen(true);
      setIsOverlayStreamer(true);
    } else if (appContext.isShareScreen === false && isShareScreen) {
      setIsShareScreen(false);
      setIsOverlayStreamer(false);
    }
  }, [appContext.isShareScreen]);

  useEffect(() => {
    100000
    window.addEventListener('beforeunload', () => {
      const ws = wsc.getWs();
      ws.send(JSON.stringify({
        type: 'stateTransitionEvent',
        event: {
          type: "logout",
          signageId,
          senderType: "Signage"
        }
      }));
    });
    return window.removeEventListener('beforeunload', () => { })
  }, [])

  useEffect(() => {
    const ws = wsc.getWs();
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        type: 'homeButton',
        event: {
          type: "homeButtonOnline",
        }
      }));
    } else {
      console.error("WebSocket is not in the OPEN state.");
    }
  }, [])
  // const date = state.state?.date
  const token = state.state?.token
  function logout() {
    localStorage.removeItem("token");
    try {
      fetch(`/token/logout`);
      window.location.href = "/logout";
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const convertTimeToSeconds = (dateString) => {
    const endTime = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
    const currentTime = moment();
    const result = endTime.diff(currentTime, 'ms');
    return result;
  };
  const getExpTime = async () => {
    try {
      console.log("getExpTime function is called")
      if (token) {
        console.log("getExpTime function is called second time")
        const sendData = { token: token };
        const param = {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(sendData),
        };

        const response = await fetch(`/token/get/expTime`, param);
        const json = await response.json();
        const result = convertTimeToSeconds(json.date)
        const alertTime = result - 300000
        if (alertTime > 0) {
          let time
          if (time) {
          } else {
            time = setTimeout(() => {
              setAlert(true);
              setTimeout(() => {
                setAlert(false)
              }, 10000)
            }, alertTime);
          }
        }
        setInterval(() => {
          logout();
          console.log("logout time is over")
        }, result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    token ? getExpTime() : <></>
  }, []);
  // logoutTime ?? convertTimeToSeconds(Lo)
  return (
    <Flex direction="column" id="signage-view-wrapper">
      <Flex>
        <VideoCaller
          // isAlert={true}
          wsc={wsc}
          signageId={signageId}
          theirId={appContext.theirId}
          theirName={appContext.theirName}
          isViewTheirVideo={false}
          isOverlayStreamer={isOverlayStreamer}
          setIsOverlayStreamer={setIsOverlayStreamer}
          receiveCount={appContext.receiveCount}
          signageState={signageState}
          isViewSignageConfig={isViewConfigurator}
          // isViewSignageConfig={true}
          templateTable={templateTable}
          setTemplateTable={setTemplateTable}
          callOfferTime={callOfferTime}
          inSignage={true}
          isMonitoring={isMonitoring}
          setIsShareScreen={setIsShareScreen}
          isShareScreen={isShareScreen}
          callRequestTime={callRequestTime}
          button={button}
          setMainTelop={setMainTelop}
          setSystemTalkLatest={setSystemTalkLatest}
          setUserTalkLatest={setUserTalkLatest}
        />
        {templateTable ? (
          <PixelStreamer
            wsc={wsc}
            isViewTheirVideo={false}
            signageState={signageState}
            createPlayerStateList={[]}
            isViewSignageConfig={false}
            setTemplateTable={setTemplateTable}
            templateTable={templateTable}
            borderNone={true}
            inSignage={true}
            isMonitoring={isMonitoring}
            isShareScreen={isShareScreen}
            setIsShareScreen={setIsShareScreen}
            callRequestTime={callRequestTime}
            selectCharacter={selectCharacter}
            settingButton={settingButton}
            disableEventTouchButton={false}
            handleClickTouchButton={(time, button) => {
              setIsCallOfferTime(time)
              setButton(button)
            }}
            mainTelop={mainTelop}
            systemTalkLatest={systemTalkLatest}
            userTalkLatest={userTalkLatest}
          />) :
          (
            <Flex>Loading...</Flex>
          )}
      </Flex>
      <AlertConfirmLogout
        isOpen={isOpen}
        onHide={onHide}
      />
      { isAlert
        ?
        <Alert
          position="absolute"
          left="50%"
          top="20px"
          zIndex="999"
          width=""
        >
          会議終了まで、あと５分
          </Alert>
        :
        ""
      }

      {/* <Textarea
        id='translatedText'
        value={mainTelop}
        // onChange={handleInputChange}
        placeholder='Here is translated'
        size='sm'
      /> */}
    </Flex>

  );
}

export default SignageView;
