// InputField.jsx
import React, { useEffect, useState } from 'react';
import { Box, Textarea } from '@chakra-ui/react';
import { ConvertFormData } from '../util/ConvertFormData';


// interface ISpeechRecognitionEvent {
//     isTrusted?: boolean;
//     results: {
//       isFinal: boolean;
//       [key: number]:
//         | undefined
//         | {
//             transcript: string;
//           };
//     }[];
//   }

//   interface ISpeechRecognition extends EventTarget {
//     // properties
//     grammars: string;
//     lang: string;
//     continuous: boolean;
//     interimResults: boolean;
//     maxAlternatives: number;
//     serviceURI: string;

//     // event handlers
//     onaudiostart: () => void;
//     onaudioend: () => void;
//     onend: () => void;
//     onerror: () => void;
//     onnomatch: () => void;
//     onresult: (event: ISpeechRecognitionEvent) => void;
//     onsoundstart: () => void;
//     onsoundend: () => void;
//     onspeechstart: () => void;
//     onspeechend: () => void;
//     onstart: () => void;

//     // methods
//     abort(): void;
//     start(): void;
//     stop(): void;
//   }

// //   export default ISpeechRecognition;

//   interface ISpeechRecognitionConstructor {
//     new (): ISpeechRecognition;
//   }

// interface IWindow extends Window {
//   SpeechRecognition: ISpeechRecognitionConstructor;
//   webkitSpeechRecognition: ISpeechRecognitionConstructor;
// }

interface IWindow extends Window {
  SpeechRecognition: any;
  webkitSpeechRecognition: any;
}

declare var window: IWindow

const InputField = (props: any) => {

  const [inputText, setInputText] = useState('');
  const [recogniziedText, setRecogniziedText] = useState('');

  const SpeechRecognition: any = window.SpeechRecognition || window.webkitSpeechRecognition;

  //   const SpeechRecognition :any = window.SpeechRecognition;
  let recognition = new SpeechRecognition();
  // recognition.lang = props.inSignage ?  'en-US' : 'ja-JP';
  recognition.lang = 'ja-JP';
  recognition.interimResults = false;
  recognition.continuous = true;

  recognition.onend = () => {
    // console.log('Audio capturing will be continued');
    recognition.start();
    return;
  }
  recognition.onaudiostart = () => {
    console.log('Audio capturing started onaudiostart')
  }
  recognition.onaudioend = () => {
    console.log("Audio capturing ended onaudioend");
  }
  recognition.error = (event) => {
    console.log('エラーが発生しました。', event.error)
  }
  recognition.onnomatch = () => {
    console.log('認識できませんでした。')
  }

  // VideoCallerにてマイク取得後の処理
  useEffect(() => {
    // コンポーネント読み込み時に音声認識を開始
    if (props.isLoadedInputAudio) {
      console.log(`[debug]audio recognition.start in props.isLoadedInputAudio.`);
      recognition.start();
    }
  }, [props.isLoadedInputAudio]);


  const sendMessage = () => {
    console.log('sendMessage')
    const targetElement = document.getElementById('recognizedText');
    let text = targetElement.getAttribute('value');
    if (text) {
      props.onSendMessage({ from: 'user', text: text })
      sendMessageToAI(text)
      setRecogniziedText(() => {
        return ''
      })
    }
  }

  // 翻訳処理
  const translateText = async (originalText: string) => {
    // Open APIにて翻訳する処理
    const values = {
      msg: originalText
    }

    const converter = new ConvertFormData();

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(values)
    };

    return new Promise((resolve, reject) =>
      fetch(`/api/translateText`, param)
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        })
    )
  }

  // AI会話処理
  const talkAI = async (originalText: string) => {
    // Open APIにて会話する処理
    const values = {
      msg: originalText
    }

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(values)
    };

    return new Promise((resolve, reject) =>
      fetch(`/api/talkAI`, param)
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        })
    )
  }

  let recognizeFlag = false
  //認識した文字の処理
  recognition.onresult = async (event) => {
    for (let i = event.resultIndex; i < event.results.length; i++) {
      let results = event.results
      if (results[i].isFinal) {
        //入力欄の値を初期化
        setInputText(() => {
          return ''
        })
        // 認識されたテキスト
        console.log(`[debug]Recoged: ${event.results[i][0].transcript}`)
        const originalText = event.results[i][0].transcript;

        // console.log(`[debug]recogniziedText.length: ${recogniziedText.length}`);
        if (originalText && originalText.length > 1) {
          // AI会話用: データを上位コンポーネントに伝達
          // サイネージ側の発言
          props.setUserTalkLatest(originalText);
          const result: any = await translateText(originalText);
          console.log(`[debug]in English: ${result.translated}`);

          // [tmp]AI会話テスト用の一時送信処理
          const aiAnswer: any = await talkAI(originalText);
          console.log(`[debug]TalkAI answered: ${aiAnswer.answered}`);

          // AI会話用: データを上位コンポーネントに伝達
          // AI側の発言
          props.setSystemTalkLatest(aiAnswer.answered);

          props.onSendMessage(result.translated);
          // props.onSendMessage(aiAnswer.answered);
        }

        console.log('認識完了')
        // if (event.results[i][0].transcript.length > 3) {
        recognizeFlag = true
        console.log('認識開始')
        continue
        // } else if (event.results[i][0].transcript.includes('終わり')) {
        //   recognizeFlag = false
        //   sendMessage()
        // }
        if (recognizeFlag) {
          setRecogniziedText((text) => text + event.results[i][0].transcript)
        }
      } else {
        console.log('認識中')
        setInputText(event.results[i][0].transcript)
        // console.log(`Recoged: ${event.results[i][0].transcript}`)
      }
    }

  }

  //外部APIの呼び出し関数
  const sendMessageToAI = (message) => {
    // APIの呼び出し

    //処理詳細

    // レスポンスの受け取り
    const response = 'APIの返り値'
    props.onSendMessage({ from: 'bot', text: response })
  };

  const deleteInputText = () => {
    console.log('入力内容のクリア')
    setRecogniziedText(() => {
      return ''
    })
  }

  return (
    // <div className="input-field">
    //   <textarea id='recognizedText' value={recogniziedText + inputText} />
    //   <button onClick={deleteInputText}>input Clear</button>
    // </div>
    <Box
      display="none"
    >
      <Textarea
        id='recognizedText'
        value={recogniziedText + inputText}
        // onChange={handleInputChange}
        placeholder='オリジナルの発言'
        size='md'
      />
    </Box>
  );
};

export default InputField;
