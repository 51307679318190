import { ConvertFormData } from './../util/ConvertFormData';
import { ApiClient } from './ApiClient';

const converter = new ConvertFormData();

export class SignageClient implements ApiClient {
    constructor() {
    }
    async get(targetId?: string) {
        const sendData = targetId ? { targetId: targetId } : {}
        const param = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(sendData)
        };
        return new Promise((resolve, reject) =>
            fetch(`/api/getSignage`, param)
                .then(response => response.json())
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    reject(error);
                })
        )
    }
    async add(values: any) {
        const param = {
            method: 'POST',
            body: converter.valuesToFormData(values)
        };
        return new Promise((resolve, reject) =>
            fetch(`/api/addSignage`, param)
                .then(response => response.json())
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    reject(error);
                })
        )

    }
    async update(values: any) {
        const param = {
            method: 'POST',
            body: converter.valuesToFormData(values)
        };
        return new Promise((resolve, reject) =>
            fetch(`/api/updateSignage`, param)
                .then(response => response.json())
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    reject(error);
                })
        )
    }
    async delete(targetId: string) {
        const sendData = targetId ? { targetId: targetId } : {}
        const param = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(sendData)
        };
        return new Promise((resolve, reject) =>
            fetch(`/api/deleteSignage`, param)
                .then(response => response.json())
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    reject(error);
                })
        )
    }
    async getStatus() {
        const testData = {
            14: "Logout",
            52: "Logout"
        }
        return testData;
    }
    async logout() {
        return fetch(`/api/logout`)
            .then(response => response.json())
            .then(json => {
                return json.result;
            })
            .catch((error) => {
            })
    }
    async talkAIrestart() {
        return fetch(`/api/talkAI/restart`)
            .then(response => response.json())
            .then(json => {
                return json.result;
            })
            .catch((error) => {
            })
    }
    
    async addCapture(values: any) {
        const param = {
            method: 'POST',
            body: converter.valuesToFormData(values)
        };
        return new Promise((resolve, reject) =>
            fetch(`/api/addCaptureImage`, param)
                .then(response => response.json())
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    reject(error);
                })
        )

    }
}